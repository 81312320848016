<template>
  <div class="home-request">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Home-Requests')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <simplebar data-simplebar-auto-hide="false" class="request">
          <div class="info">
            <div class="desc-left">
              <img :src="require(`@/assets/icons/${request_data.avatar}`)" alt="Avatar" />
              <div class="desc">
                <p>{{ request_data.username }}</p>
                <div class="xxs">
                  <span class="title">{{ request_data.game_name }}</span> |
                  <time-ago class="time" :refresh="60" :datetime="request_data.created_at" long locale="de"></time-ago>
                </div>
              </div>
            </div>
            <div class="desc-right" v-click-outside="hideInfobox">
              <div class="btn" @click="toggleInfobox"></div>
              <InfoboxRequest v-show="showInfobox" v-bind:data="request_data" />
            </div>
          </div>
          <div class="txt">{{ request_data.description }}</div>
          <div class="search-mode__wrapper">
            <div v-if="request_data.search_mode === 'lfg'" class="lfg">
              <div class="search-mode">LFG:</div>
              <div class="desc">Dieser Spieler sucht nach einer Gruppe von Mitspielern. Sende eine Anfrage, um mit dem Spieler in Kontakt zu kommen!</div>
            </div>

            <div v-else class="lfm">
              <div class="search-mode">LFM:</div>
              <div class="desc__wrapper">
                <div class="desc">
                  Eine bereits bestehende Gruppe sucht nach weiteren Mitspielern. Sende eine Anfrage, um mit dem Spieler in Kontakt zu kommen!
                </div>
                <div class="player-count">
                  Benötigte Mitspieler: <span>{{ request_data.player_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </simplebar>

        <button class="button__cta" v-if="request_data.user_id !== authId" @click="sendMessage">Team Me!</button>
        <p class="btn-desc" v-if="request_data.user_id !== authId">Sende dem Spieler eine Anfrage!</p>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import InfoboxRequest from "@/components/InfoboxRequest.vue";
import TimeAgo from "vue2-timeago";

export default {
  name: "HomeRequest",
  components: {
    simplebar,
    InfoboxRequest,
    TimeAgo,
  },
  data() {
    return {
      showInfobox: false,
    };
  },
  methods: {
    toggleInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    hideInfobox() {
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
    showComponent(name) {
      this.$store.commit("SET_HOME_COMPONENT", name);
    },
    sendMessage() {
      let rooms = this.$store.getters.MY_CHAT_ROOMS;
      let chat_partner_ids = [];
      let data = [];
      for (let i = 0; i < rooms.length; i++) {
        chat_partner_ids.push(rooms[i].participants[0].id);
        data.push({
          user_id: rooms[i].participants[0].id,
          room_id: rooms[i].id,
        });
      }
      if (chat_partner_ids.includes(this.request_data.user_id)) {
        // Chat exists
        let roomId = data.find((x) => x.user_id === this.request_data.user_id).room_id;
        let participant_data = this.request_data;
        let user_data = [];
        // push necessary data to array
        user_data.push({
          id: participant_data.user_id,
          username: participant_data.username,
          avatar: participant_data.avatar,
        });
        let payload = {
          chatRoomId: roomId,
          participants: user_data,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      } else {
        // Chat does not exist
        let payload = {
          user_id: this.request_data.user_id,
          username: this.request_data.username,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      }

      // Redirect User to Chat Window
      this.$store.commit("SET_SOCIALHUB_COMPONENT", "Chat");
      this.$router.push("/SocialHub");
      this.$store.commit("SET_HOME_COMPONENT", "Home-Requests");
    },
  },
  computed: {
    request_data() {
      return this.$store.state.teamee_request_data;
    },
    authId() {
      return this.$store.getters.USER.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.home-request {
  display: flex;
  height: calc(100vh - 295px);
  width: 800px;
  margin: 80px auto 0;
  @media screen and (max-width: 960px) {
    position: fixed;
    width: 100%;
    margin-top: 0;
    height: calc(100vh - 175px);
  }
  .content-wrapper {
    width: 100%;
    border-radius: 5px;
    background-color: $darker-navy-blue;
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      padding: 12px 24px;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .request {
        padding: 24px 16px 0 0;
        width: calc(100% + 16px);
        height: calc(100vh - 500px);
        @media screen and (max-width: 960px) {
          height: calc(100vh - 360px);
        }
        &:hover {
          background-color: $darker-navy-blue;
        }
        .info {
          display: flex;
          justify-content: space-between;
          .desc-left {
            flex-grow: 1;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            img {
              width: 40px;
              margin-right: 20px;
              user-select: none;
            }
            .desc {
              display: flex;
              flex-direction: column;
              p,
              .xxs {
                line-height: 1;
              }
              p {
                margin-bottom: 6px;
              }
              .title {
                margin-right: 6px;
              }
              .time {
                margin-left: 6px;
              }
            }
          }
          .desc-right {
            position: relative;
            .btn {
              width: 40px;
              height: 40px;
              background-image: url("../assets/icons/multi-options.svg");
              background-size: cover;
              cursor: pointer;
              &:active {
                filter: brightness(50%);
              }
            }
          }
        }
        .txt {
          padding-left: 60px;
          line-height: 1.5;
          @media screen and (max-width: 960px) {
            padding-left: 0;
          }
        }
        .search-mode__wrapper {
          display: flex;
          padding-left: 60px;
          margin-top: 32px;
          @media screen and (max-width: 960px) {
            padding-left: 0;
          }
          .lfg,
          .lfm {
            display: flex;
            .search-mode {
              margin-right: 8px;
              color: $orange;
              .desc {
                flex-grow: 1;
              }
            }
            .desc__wrapper {
              display: flex;
              flex-direction: column;
              .player-count {
                margin-top: 24px;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .button__cta {
        width: 400px;
        align-self: center;
        margin-top: auto;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
        }
      }
      .btn-desc {
        text-align: center;
        color: $grey;
        font-size: 14px;
        line-height: 28px;
        width: 400px;
        align-self: center;
        margin-bottom: 24px;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }
}
</style>
