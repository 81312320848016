<template>
  <div class="home">
    <main>
      <component :is="component" />
    </main>
  </div>
</template>

<script>
import HomeRequests from "@/components/Home-Requests.vue";
import HomeRequest from "@/components/Home-Request.vue";

export default {
  name: "Home",
  components: {
    HomeRequests,
    HomeRequest,
  },
  computed: {
    component() {
      return this.$store.getters.HOMECOMPONENT;
    },
    userId() {
      return this.$store.getters.USER.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.home {
  main {
    min-height: calc(100vh - 83px);
    padding: 132px 40px 0 40px;
    @media screen and (max-width: 960px) {
      padding: 92px 0 0;
    }
  }
}
</style>
