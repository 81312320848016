<template>
  <div class="infobox-request">
    <div class="row" @click="addFriend" v-if="data.user_id !== authId">
      <span class="loader" v-if="isLoading"></span>
      <img class="icon" v-else src="@/assets/icons/add-person_dark.svg" alt="Add Friend" />
      <p>als Freund hinzufügen</p>
    </div>
    <div class="row" v-if="data.user_id !== authId" @click="sendMessage">
      <img class="icon" src="@/assets/icons/write-message_dark.svg" alt="Send Message" />
      <p>Nachricht senden</p>
    </div>

    <div class="row" v-else @click="deleteRequest">
      <span class="loader" v-if="isLoading"></span>
      <img class="icon" v-else src="@/assets/icons/delete_dark.svg" alt="Delete Request" />
      <p>Anfrage löschen</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoboxRequest",
  data() {
    return {
      isLoading: false,
    };
  },
  props: ["data"],
  methods: {
    addFriend() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch("addFriend", {
            user_id: this.$props.data.user_id,
          })
          .then(() => {
            this.isLoading = false;
            this.flash("Freundschaftsanfrage erfolgreich verschickt!", "success", { timeout: 3500 });
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.isLoading = false;
              this.flash(error.response.data.msg, "error", { timeout: 3500 });
            } else {
              this.isLoading = false;
              this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
            }
          });
      }
    },
    deleteRequest() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch("deleteTeameeRequest", { game_id: this.$props.data.game_id })
          .then(async () => {
            let myGames = this.$store.state.user.my_games;
            const index = myGames.indexOf(this.$props.data.game_id);
            if (index > -1) {
              this.$store.state.user.my_games.splice(index, 1);
            }
            // set games null, if last request has been deleted
            if (index == 0) {
              this.$store.commit("SET_USER_MY_GAMES", null);
              this.$store.state.myGames = [];
              this.$store.commit("SET_HOME_COMPONENT", "Home-Requests");
            }
            this.$store.dispatch("getMyGames");
            this.activeItem = null;
            this.isLoading = false;
            this.$store.commit("SET_HOME_COMPONENT", "Home-Requests");
          })
          .catch(() => {
            this.flash("Oops... Da ist wohl etwas schief gelaufen", "error", { timeout: 3500 });
            this.isLoading = false;
          });
      }
    },
    sendMessage() {
      let rooms = this.$store.getters.MY_CHAT_ROOMS;
      let chat_partner_ids = [];
      let data = [];
      for (let i = 0; i < rooms.length; i++) {
        chat_partner_ids.push(rooms[i].participants[0].id);
        data.push({
          user_id: rooms[i].participants[0].id,
          room_id: rooms[i].id,
        });
      }
      if (chat_partner_ids.includes(this.$props.data.user_id)) {
        // Chat exists
        let roomId = data.find((x) => x.user_id === this.$props.data.user_id).room_id;
        let participant_data = this.$props.data;
        let user_data = []; // push necessary data to array
        user_data.push({
          id: participant_data.user_id,
          username: participant_data.username,
          avatar: participant_data.avatar,
        });
        let payload = {
          chatRoomId: roomId,
          participants: user_data,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      } else {
        // Chat does not exist
        let payload = {
          user_id: this.$props.data.user_id,
          username: this.$props.data.username,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      }

      this.$store.commit("SET_SOCIALHUB_COMPONENT", "Chat");
      this.$router.push("/SocialHub");
      this.$store.commit("SET_HOME_COMPONENT", "Home-Requests");
    },
  },
  computed: {
    authId() {
      return this.$store.getters.USER.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.infobox-request {
  user-select: none;
  cursor: default;
  position: absolute;
  padding: 12px 14px;
  background-color: #d8d8d8;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  right: 0;
  top: 60px;
  @media screen and (max-width: 960px) {
    right: -2px;
  }
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    bottom: 100%;
    right: 10px;
    content: "";
    transform: rotate(45deg);
    margin-bottom: -12px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px 4px $orange;
    @media screen and (max-width: 960px) {
      right: 12px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: 960px) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    cursor: pointer;
    .icon {
      width: 28px;
      margin-left: 4px;
      margin-right: 14px;
    }
    p {
      color: $dark-navy-blue;
      white-space: nowrap;
    }
  }
  .loader {
    border: 2px solid $grey;
    margin-right: 8px;
    &:after {
      border-color: $orange transparent;
    }
  }
}
</style>
