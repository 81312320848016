<template>
  <div class="infobox-requests">
    <div class="row" @click="changeTeameeRequestsFilter(0)">
      <div class="indicator" :class="{ active: teameeRequestsFilter === 0 }"></div>
      <p>alle teamee Requests</p>
    </div>
    <div class="row" @click="changeTeameeRequestsFilter(1)">
      <div class="indicator" :class="{ active: teameeRequestsFilter === 1 }"></div>
      <p>nur LFG</p>
    </div>
    <div class="row" @click="changeTeameeRequestsFilter(2)">
      <div class="indicator" :class="{ active: teameeRequestsFilter === 2 }"></div>
      <p>nur LFM</p>
    </div>
    <router-link class="row link" to="Teamee">
      <img class="icon" src="@/assets/icons/add-grey.svg" alt="Add" />
      <p>Games hinzufügen</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "InfoboxRequests",
  methods: {
    // change teamee Requests Filter Mode + fetch new Requests
    changeTeameeRequestsFilter(mode) {
      this.$store.commit("SET_TEAMEE_REQUESTS_FILTER", mode);
      this.$store
        .dispatch("getTeameeRequests", {
          teameeRequestsFilter: this.teameeRequestsFilter,
        })
        .then((response) => {
          this.$store.commit("SET_TEAMEE_REQUESTS", response.data);
        })
        .catch(() => {
          this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
        });
    },
  },
  computed: {
    teameeRequestsFilter() {
      return this.$store.getters.TEAMEE_REQUESTS_FILTER;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.infobox-requests {
  user-select: none;
  cursor: default;
  position: absolute;
  padding: 12px 14px;
  background-color: #d8d8d8;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  right: 0;
  top: 60px;
  @media screen and (max-width: 960px) {
    right: -2px;
  }
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    bottom: 100%;
    right: 10px;
    content: "";
    transform: rotate(45deg);
    margin-bottom: -12px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px 4px $orange;
    @media screen and (max-width: 960px) {
      right: 12px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: 960px) {
      margin-bottom: 16px;
    }

    &:hover {
      p {
        text-decoration: underline;
      }
    }
    cursor: pointer;
    .icon {
      width: 28px;
      margin-left: 4px;
      margin-right: 14px;
    }
    .indicator {
      height: 28px;
      width: 28px;
      border: 2px solid $navy-blue;
      border-radius: 100%;
      margin: 0 14px 0 4px;
    }
    .active {
      background: $gradient;
    }
    p {
      color: $dark-navy-blue;
      white-space: nowrap;
    }
  }
  .link {
    margin-bottom: 0;
  }
}
</style>
