<template>
  <div class="home-requests">
    <div v-if="userHasGames" class="requests__wrapper">
      <div class="btn__wrapper">
        <div class="games__wrapper">
          <div
            class="game"
            :class="{ active: index === activeItem }"
            @click="selectItem(index)"
            v-for="(item, index) in games"
            :key="index"
            v-bind:style="{ backgroundImage: 'url(' + games[index].background_image + ')' }"
            :title="games[index].name"
          >
            <div v-if="games[index].background_image === null" class="noImage">{{ games[index].name }}</div>
          </div>
        </div>
        <div class="btn-wrapper" v-click-outside="hideInfobox">
          <div class="btn" @click="toggleInfobox"></div>
          <InfoboxRequests v-show="showInfobox" />
        </div>
      </div>

      <!-- Requests -->
      <div class="requests__wrapper">
        <div v-if="requests">
          <div class="requests" v-if="(!isLoading && requests[currentGameId].data.length) || requests[currentGameId].data.length">
            <div
              class="request"
              v-for="(item, index) in requests[currentGameId].data"
              :key="index"
              @click="
                showComponent('Home-Request');
                storeTeameeRequestData(item);
              "
            >
              <div class="info">
                <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
                <div class="desc">
                  <p>{{ item.username }}</p>
                  <div class="xs">
                    <span class="title">{{ games[activeItem].name }}</span> |
                    <time-ago class="time" :refresh="60" :datetime="item.created_at" long locale="de"></time-ago>
                  </div>
                </div>
              </div>
              <div class="txt">{{ item.description }}</div>
            </div>
            <!-- loading Indicator for new data -->
            <div class="loader__wrapper" v-if="newDataLoading">
              <span class="loader"></span>
            </div>
          </div>
          <div v-else class="no-requests">
            <div class="request">
              <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
              <p>Leider konnten für dieses Spiel keine Anfragen von anderen Spielern gefunden werden.</p>
            </div>
          </div>
        </div>
        <div v-else class="no-requests">
          <span class="loader" v-if="isLoading"></span>
          <div v-else class="request">
            <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
            <p>Leider konnten für dieses Spiel keine Anfragen von anderen Spielern gefunden werden.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="noResults__wrapper">
      <div class="noResults">
        <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
        <h2>Ziemlich leer hier …</h2>
        <p>Passe deine Suchkriterien an, um passende Mitspieler zu finden.</p>
        <router-link to="/Teamee"><button class="button__cta">Nach Mitspielern suchen</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import InfoboxRequests from "@/components/InfoboxRequests.vue";
import TimeAgo from "vue2-timeago";

export default {
  name: "Home-Requests",
  components: {
    InfoboxRequests,
    TimeAgo,
  },
  data() {
    return {
      isLoading: true,
      newDataLoading: false,
      activeItem: 0,
      showInfobox: false,
    };
  },
  methods: {
    selectItem(index) {
      if (this.activeItem !== index) {
        this.activeItem = index;
      }
    },
    toggleInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    hideInfobox() {
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
    showComponent(name) {
      this.$store.commit("SET_HOME_COMPONENT", name);
    },
    storeTeameeRequestData(item) {
      item.game_name = this.games[this.activeItem].name;
      this.$store.state.teamee_request_data = item;
    },
    getTeameeRequests() {
      this.$store
        .dispatch("getTeameeRequests", {
          teameeRequestsFilter: this.$store.getters.TEAMEE_REQUESTS_FILTER,
        })
        .then((response) => {
          this.isLoading = false;
          this.$store.commit("SET_TEAMEE_REQUESTS", response.data);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    // Load more Request, if User scrolls to Bottom of Page
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

        if (bottomOfWindow && this.requests[this.currentGameId].next_page_url !== null && !this.newDataLoading) {
          this.newDataLoading = true;
          this.$store
            .dispatch("loadMoreRequests", {
              url: this.requests[this.currentGameId].next_page_url,
              game_id: this.currentGameId,
            })
            .then((response) => {
              this.newDataLoading = false;
              this.$store.commit("ADD_TEAMEE_REQUESTS", response);
            })
            .catch(() => {
              this.newDataLoading = false;
            });
        }
      };
    },
  },
  // get User's Games
  computed: {
    userHasGames() {
      return this.$store.getters.USER.my_games;
    },
    games() {
      return this.$store.getters.MY_GAMES;
    },
    currentGameId() {
      return this.games[this.activeItem].id;
    },
    requests() {
      return this.$store.getters.MY_TEAMEE_REQUESTS;
    },
  },
  mounted() {
    // call API for GameInfo
    let gameData = this.$store.getters.MY_GAMES;
    let localStorageGameData = this.$store.getters.USER.my_games;

    if (localStorageGameData !== null) {
      if (gameData.length !== localStorageGameData.length) {
        this.$store.dispatch("getMyGames");
      }
    }

    // get Teamee Request
    this.getTeameeRequests();

    // check if user scrolled to bottom to load more Requests
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.home-requests {
  .noResults__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 215px);
    padding: 0 24px;
    .noResults {
      margin: auto;
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 48px;
      }
      h2 {
        @media screen and (max-width: 960px) {
          font-size: 32px;
          line-height: 1.5;
          margin-bottom: 12px;
        }
      }
      p {
        margin-bottom: 64px;
      }
      a,
      .button__cta {
        width: 100%;
      }
    }
  }
  .requests__wrapper {
    display: flex;
    flex-direction: column;
    width: 640px;
    margin: auto;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .btn__wrapper {
      position: fixed;
      top: 0;
      height: 240px;
      width: 640px;
      background-color: $navy-blue;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 16px;
      @media screen and (max-width: 960px) {
        height: 160px;
        width: 100%;
        padding: 0 24px 8px 24px;
      }
      .games__wrapper {
        display: flex;
        width: calc(100% - 54px);
        max-width: 340px;
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
          height: 0;
          width: 0;
        }
        .game {
          margin-right: 8px;
          min-width: 56px;
          height: 56px;
          border-radius: 5px;
          background-color: rgba(93, 92, 92, 0.767);
          cursor: pointer;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          .noImage {
            max-width: 51px;
            max-height: 51px;
            text-align: center;
            font-size: 10px;
            overflow: hidden;
          }
          &:hover {
            border: 2px solid $orange;
          }
        }
        .active {
          border: 2px solid $orange;
        }
      }
      .btn-wrapper {
        position: relative;
        .btn {
          width: 40px;
          height: 40px;
          background-image: url("../assets/icons/multi-options.svg");
          background-size: cover;
          cursor: pointer;
          &:active {
            filter: brightness(50%);
          }
        }
      }
    }

    .requests {
      margin-top: 120px;
      @media screen and (max-width: 960px) {
        margin: 80px 0 120px;
      }
      .request {
        padding: 20px;
        border: 1px solid $light-grey;
        cursor: pointer;
        &:hover {
          background-color: $darker-navy-blue;
        }
        &:nth-of-type(even) {
          border-bottom: none;
          border-top: none;
        }
        &:last-of-type {
          border-bottom: 1px solid $light-grey;
        }
        @media screen and (max-width: 960px) {
          border-left: none;
          border-right: none;
        }
        .info {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
          img {
            width: 40px;
            margin-right: 20px;
            user-select: none;
          }
          .desc {
            display: flex;
            flex-direction: column;
            p,
            .xs {
              line-height: 1;
            }
            p {
              margin-bottom: 6px;
            }
            .title {
              margin-right: 6px;
            }
            .time {
              margin-left: 6px;
            }
          }
        }
        .txt {
          padding-left: 60px;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @media screen and (max-width: 960px) {
            padding-left: 0;
            font-size: 14px;
            -webkit-line-clamp: 2;
          }
        }
      }
      .loader__wrapper {
        border-top: 1px solid $light-grey;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .no-requests {
      margin-top: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 960px) {
        margin: 80px 0 120px;
      }
      .loader {
        margin-top: 40px;
      }
      .request {
        padding: 24px 32px;
        border: 1px solid $light-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 24px;
        }
        p {
          text-align: center;
        }
      }
    }
  }
  .noResults__wrapper {
    img {
      width: 80px;
      margin: 0 auto;
    }
  }
}
</style>
